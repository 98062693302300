
  import ModalDialog from "@/components/ModalDialog.vue";
  import {computed, defineComponent, reactive, Ref, ref, toRefs} from "vue";
  import {InventoryConfirmation} from "@/model/InventoryConfirmation";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import {helpers, minValue, numeric, requiredIf} from "@vuelidate/validators";
  import {t, tWithParms} from "@/mixin/mixins";
  import {VALUE} from "@/plugins/i18n";
  import InventoryConfirmationService, {
    InventoryConfirmationRequestStorageDetails
    } from "@/service/InventoryConfirmationService";

  export default defineComponent({
    name: "OrdersPlanningInventoryConfirmationDialog",
    components: {
      ModalDialog
    },
    setup(props, context) {

      const initialState = {
        confirmed: false,
        dialog: false,
        model: new InventoryConfirmation(),
        result: (updated: boolean | PromiseLike<boolean>) => {
        }
      };

      const state = reactive({...initialState});

      const resetState = () => {
        Object.assign(state, initialState);
      };

      const rules = computed(() => ({
        model: {
          storageDetails: {
            storageRollCageCount: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), requiredIf(function (): boolean {
                return state.model.categories.storage;
              })),
              numeric: helpers.withMessage(tWithParms("validation.numeric", t(VALUE)), numeric),
              minValue: helpers.withMessage(tWithParms("validation.greaterThanOrEqualTo", t(VALUE), "0"), minValue(0))
            },
            storageFreezerRollCageCount: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), requiredIf(function (): boolean {
                return state.model.categories.storage;
              })),
              numeric: helpers.withMessage(tWithParms("validation.numeric", t(VALUE)), numeric),
              minValue: helpers.withMessage(tWithParms("validation.greaterThanOrEqualTo", t(VALUE), "0"), minValue(0))
            }
          }
        }
      }
      ));

      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        const model = v$.value;
        if (model.$dirty) {
          model.$errors.forEach((error: ErrorObject) => {
            if (!errors.has(error.$propertyPath)) {
              // @ts-ignore
              errors.set(error.$propertyPath, error.$message);
            }
          });
        }

        return errors;
      });

      const title = ref<string>("");

      const close = () => {
        state.dialog = false;
        state.result(false);
        resetState();
      };

      const open = (inventoryConfirmation: InventoryConfirmation) => {
        resetState();
        if (inventoryConfirmation.confirmationTimestamp) {
          state.confirmed = true;
        }
        state.dialog = true;
        state.model = Object.assign(new InventoryConfirmation(), JSON.parse(JSON.stringify(inventoryConfirmation)));

        return new Promise<boolean>((resolve, reject) => {
          state.result = resolve;
        });
      };

      const save = () => {
        v$.value.$reset();
        v$.value.$touch();

        if (v$.value.$error) {
          return;
        }

        // Only populate if storage is selected
        let storageDetails = undefined as unknown as InventoryConfirmationRequestStorageDetails;
        if (state.model.categories.storage) {
          storageDetails = {
            storageRollCageCount: state.model.storageDetails?.storageRollCageCount,
            storageFreezerRollCageCount: state.model.storageDetails?.storageFreezerRollCageCount
          };
        }

        InventoryConfirmationService.updateInventory(state.model.uuid, {
          categories: {
            flowers: state.model.categories.flowers,
            fruitAndVegetables: state.model.categories.fruitAndVegetables,
            labels: state.model.categories.labels,
            storage: state.model.categories.storage,
            targetActualComparison: state.model.categories.targetActualComparison
          },
          comment: state.model.comment,
          confirm: state.confirmed,
          storageDetails: storageDetails
        }).then(response => {
          state.dialog = false;
          state.result(true);
          resetState();
        });
      };

      return {
        ...toRefs(state),
        errors,
        title,
        close,
        open,
        save
      };
    }
  });
