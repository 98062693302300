import axios, {AxiosResponse} from "axios";
import {PagedResult} from "@/service/PagedResult";
import {InventoryConfirmation, InventoryConfirmationCategories} from "@/model/InventoryConfirmation";

const PATH = "/confirmations";

export interface InventoryConfirmationRequestStorageDetails {
  storageRollCageCount: number | undefined,
  storageFreezerRollCageCount: number | undefined;
}

export interface InventoryConfirmationRequest {
  categories: InventoryConfirmationCategories,
  comment?: string;
  confirm?: boolean;
  storageDetails?: InventoryConfirmationRequestStorageDetails | null;
}

export default {
  confirmInventory(uuid: string, request: InventoryConfirmationRequest): Promise<AxiosResponse<InventoryConfirmation>> {
    return axios.post(`${PATH}/${uuid}/confirm`, request);
  },
  getConfirmation(uuid: string): Promise<AxiosResponse<InventoryConfirmation>> {
    return axios.get(`${PATH}/${uuid}`);
  },
  getConfirmations(from: string, to: string): Promise<AxiosResponse<PagedResult>> {
    return axios.get(`${PATH}`, {
      params: {
        from: from,
        to: to,
      }
    });
  },
  updateInventory(uuid: string, request: InventoryConfirmationRequest): Promise<AxiosResponse<InventoryConfirmation>> {
    return axios.post(`${PATH}/${uuid}`, request);
  },
};
