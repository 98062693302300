export class InventoryConfirmation {
  address!: string;
  categories: InventoryConfirmationCategories = new InventoryConfirmationCategories();
  changedByCustomer?: boolean;
  city!: string;
  comment?: string;
  confirmationTimestamp?: string;
  date!: string;
  expired?: boolean;
  name!: string;
  orderId!: number;
  startTime!: string;
  storageDetails: InventoryConfirmationStorageDetails = new InventoryConfirmationStorageDetails();
  uuid!: string;
  zip!: string;
}

export class InventoryConfirmationCategories {
  flowers!: boolean;
  fruitAndVegetables!: boolean;
  labels!: boolean;
  storage!: boolean;
  targetActualComparison!: boolean;
}

export class InventoryConfirmationStorageDetails {
  storageRollCageCount?: number;
  storageFreezerRollCageCount?: number;
}
