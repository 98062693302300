
  import {computed, defineComponent, onMounted, reactive, ref, Ref, toRefs} from "vue";
  import {useRouter} from "vue-router/composables";
  import InventoryConfirmationService, {
    InventoryConfirmationRequestStorageDetails
    } from "@/service/InventoryConfirmationService";
  import {InventoryConfirmation} from "@/model/InventoryConfirmation";
  import {t, tWithParms} from "@/mixin/mixins";
  import {helpers, minValue, numeric, requiredIf} from "@vuelidate/validators";
  import {VALUE} from "@/plugins/i18n";
  import useVuelidate, {ErrorObject} from "@vuelidate/core";
  import ConfirmDialogComposition from "@/components/ConfirmDialogComposition.vue";

  export default defineComponent({
    name: "InventoryConfirmation",
    components: {
      ConfirmDialogComposition
    },
    setup(props, context) {
      let router = useRouter();

      const initialState = reactive({
        alreadyConfirmed: false,
        confirmationSuccess: false,
        expired: false,
        inventoryConfirmation: new InventoryConfirmation(),
        loading: true,
        notFound: false,
        storageEnabled: false,
        targetActualComparisonEnabled: false
      });

      const state = reactive({...initialState});

      const rules = computed(() => ({
        inventoryConfirmation: {
          storageDetails: {
            storageRollCageCount: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), requiredIf(function (): boolean {
                return state.inventoryConfirmation.categories.storage;
              })),
              numeric: helpers.withMessage(tWithParms("validation.numeric", t(VALUE)), numeric),
              minValue: helpers.withMessage(tWithParms("validation.greaterThanOrEqualTo", t(VALUE), "0"), minValue(0))
            },
            storageFreezerRollCageCount: {
              required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), requiredIf(function (): boolean {
                return state.inventoryConfirmation.categories.storage;
              })),
              numeric: helpers.withMessage(tWithParms("validation.numeric", t(VALUE)), numeric),
              minValue: helpers.withMessage(tWithParms("validation.greaterThanOrEqualTo", t(VALUE), "0"), minValue(0))
            }
          }
        }
      }
      ));

      let v$ = useVuelidate(rules, state);

      const errors = computed(() => {
        const errors = new Map<string, string | Ref<string>>();
        const model = v$.value;
        if (model.$dirty) {
          model.$errors.forEach((error: ErrorObject) => {
            if (!errors.has(error.$propertyPath)) {
              // @ts-ignore
              errors.set(error.$propertyPath, error.$message);
            }
          });
        }

        return errors;
      });

      const storageNotOrderableMessage = computed(() => {
        if (state.storageEnabled) {
          return [];
        } else {
          return [t("order.inventoryConfirmations.serviceNotOrderable")];
        }
      });

      const targetActualComparisonNotOrderableMessage = computed(() => {
        if (state.targetActualComparisonEnabled) {
          return [];
        } else {
          return [t("order.inventoryConfirmations.serviceNotOrderable")];
        }
      });

      const confirmDialog = ref<InstanceType<typeof ConfirmDialogComposition>>();

      const confirmInventory = () => {
        v$.value.$reset();
        v$.value.$touch();

        if (v$.value.$error) {
          return;
        }

        const title = t("order.inventoryConfirmations.confirmInventory.title");
        const text = t("order.inventoryConfirmations.confirmInventory.text");

        confirmDialog.value?.open(title, text)
          .then((confirmResult: boolean) => {
            if (confirmResult) {
              // Only populate if storage is selected
              let storageDetails = undefined as unknown as InventoryConfirmationRequestStorageDetails;
              if (state.inventoryConfirmation.categories.storage) {
                storageDetails = {
                  storageRollCageCount: state.inventoryConfirmation.storageDetails?.storageRollCageCount,
                  storageFreezerRollCageCount: state.inventoryConfirmation.storageDetails?.storageFreezerRollCageCount
                };
              }

              InventoryConfirmationService.confirmInventory(uuid.value, {
                categories: {
                  flowers: state.inventoryConfirmation.categories.flowers,
                  fruitAndVegetables: state.inventoryConfirmation.categories.fruitAndVegetables,
                  labels: state.inventoryConfirmation.categories.labels,
                  storage: state.inventoryConfirmation.categories.storage,
                  targetActualComparison: state.inventoryConfirmation.categories.targetActualComparison
                },
                comment: state.inventoryConfirmation.comment,
                storageDetails: storageDetails
              }).then(response => {
                state.confirmationSuccess = true;
              });
            }
          });
      };

      const uuid = computed(() => {
        return router.currentRoute.params.uuid;
      });

      onMounted(() => {
        InventoryConfirmationService.getConfirmation(uuid.value)
          .then(response => {
            state.inventoryConfirmation = Object.assign(new InventoryConfirmation(), response.data);
            if (state.inventoryConfirmation.categories.targetActualComparison) {
              // if it was enabled already, then it can be disabled now
              state.targetActualComparisonEnabled = response.data.categories.targetActualComparison;
            }

            if (state.inventoryConfirmation.categories.storage) {
              // if it was enabled already, then it can be disabled now
              state.storageEnabled = response.data.categories.storage;
            }
          })
          .catch((error) => {
            if (error.response.status == 404) {
              state.notFound = true;
            } else if (error.response.status == 410) {
              if (error.response.headers["x-already-confirmed"] === "true") {
                state.alreadyConfirmed = true;
              } else if (error.response.headers["x-expired"] === "true") {
                state.expired = true;
              }
            }
          })
          .finally(() => state.loading = false);
      });

      return {
        ...toRefs(state),
        confirmDialog,
        confirmInventory,
        errors,
        storageNotOrderableMessage,
        targetActualComparisonNotOrderableMessage
      };
    }
  });
