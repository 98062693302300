
  import {useVuelidate} from "@vuelidate/core";
  import CurrencyField from "@/components/CurrencyField.vue";
  import {CashFlowInterface} from "@/model/CashFlow";
  import {CashFlowType, cashFlowTypeSelectionItems} from "@/enums";
  import StatisticService from "@/service/StatisticService";
  import {defineComponent, reactive, ref, SetupContext} from "vue";
  import {OnCloseCallback, OnSaveCallback} from "@/core/DialogCallbacks";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import {ADDING_ENTRY, ENTRY_ADDED, ENTRY_UPDATED, UPDATING_ENTRY, VALUE} from "@/plugins/i18n";
  import {helpers, required} from "@vuelidate/validators";
  import {errorMessage} from "@/ValidationUtils";
  import Order from "@/model/Order";
  import {t, tWithParms} from "@/mixin/mixins";

  function callbacks() {
    const onSaveCallback = ref<OnSaveCallback<CashFlowInterface>>();
    const onCloseCallback = ref<OnCloseCallback>();
    return {
      onSaveCallback,
      onCloseCallback
    };
  }

  function validation(context: SetupContext, model: CashFlowInterface) {
    const rules = {
      amount: {required: helpers.withMessage(tWithParms("validation.required", t(VALUE)), required)},
      date: {required}
    };

    const v$ = useVuelidate(rules, model);

    return {
      v$
    };
  }

  export const useDialog = (context: SetupContext) => {
    const isDialogOpened = ref<boolean>(false);
    const isLoading = ref<boolean>(false);
    const title = ref<string>("");
    const orderIdRef = ref<number>();
    const {onSaveCallback, onCloseCallback} = callbacks();

    const model = reactive<CashFlowInterface>({
      orderName: "",
      date: "",
      amount: 0.00,
      cashFlowType: CashFlowType.IN
    });

    const createCashFlow = (order: Order, onSave?: OnSaveCallback<CashFlowInterface>, onClose?: OnCloseCallback): void => {
      orderIdRef.value = order.id;
      title.value = t("cashFlow.createCashFlow");
      isDialogOpened.value = true;
      isLoading.value = true;

      onSaveCallback.value = onSave;
      onCloseCallback.value = onClose;

      StatisticService.defaultCashFlow(order.id)
        .then(response => {
          Object.assign(model, response.data);
        })
        .finally(() => {
          isLoading.value = false;
          if (order.name) {
            model.orderName = order.name;
          }
        });
    };

    const editCashFlow = (order: Order, cashFlow: CashFlowInterface, onSave?: OnSaveCallback<CashFlowInterface>, onClose?: OnCloseCallback): void => {
      orderIdRef.value = order.id;
      title.value = t("cashFlow.editCashFlow");
      Object.assign(model, cashFlow);
      if (order.name) {
        model.orderName = order.name;
      }
      isDialogOpened.value = true;

      onSaveCallback.value = onSave;
      onCloseCallback.value = onClose;
    };

    return {
      createCashFlow,
      editCashFlow,
      isDialogOpened,
      isLoading,
      title,
      model,
      orderId: orderIdRef,
      onSaveCallback,
      onCloseCallback
    };
  };

  export default defineComponent({
    name: "StatisticOrderCashFlowDetailsDialog2",
    components: {
      CurrencyField
    },
    setup(props, context) {
      const yearMonthMenu = ref<boolean>(false);

      const {cashFlowTypes} = cashFlowTypeSelectionItems();
      const {
        createCashFlow,
        editCashFlow,
        isDialogOpened,
        isLoading,
        title,
        model,
        orderId,
        onSaveCallback,
        onCloseCallback
      } = useDialog(context);

      const {v$} = validation(context, model);
      const cancel = () => {
        isDialogOpened.value = false;
        isLoading.value = false;
        onCloseCallback.value?.call(this);
      };

      const save = () => {
        v$.value.$reset();
        v$.value.$touch();
        if (!v$.value.$invalid) {
          if (model.id) {
            eventBus.$emit(MessageTypes.LOADING, t(UPDATING_ENTRY));
            StatisticService.updateCashFlow(orderId.value!, model.id, model)
              .then(() => {
                eventBus.$emit(MessageTypes.SUCCESS, t(ENTRY_UPDATED));
                isDialogOpened.value = false;
                isLoading.value = false;
                if (model) {
                  onSaveCallback.value?.call(this, model);
                }
              });
          } else {
            eventBus.$emit(MessageTypes.LOADING, t(ADDING_ENTRY));
            StatisticService.createCashFlow(orderId.value!, model)
              .then(response => {
                Object.assign(model, response.data);
                eventBus.$emit(MessageTypes.SUCCESS, t(ENTRY_ADDED));
                isDialogOpened.value = false;
                isLoading.value = false;
                if (model) {
                  onSaveCallback.value?.call(this, model);
                }
              });
          }
        }
      };
      return {
        cancel,
        cashFlowTypes,
        createCashFlow,
        editCashFlow,
        dialog: isDialogOpened,
        loading: isLoading,
        title,
        errorMessage,
        model,
        save,
        yearMonthMenu,
        v$
      };
    }
  });
