import {onMounted, ref, SetupContext} from "vue";
import PageUtils from "@/components/PageUtils";
import {InventoryConfirmation} from "@/model/InventoryConfirmation";
import InventoryConfirmationService from "@/service/InventoryConfirmationService";
import moment from "moment/moment";

export const initDialog = (context: SetupContext) => {
  const confirmations = ref<Array<InventoryConfirmation>>(new Array<InventoryConfirmation>());

  const fetchConfirmations = (from: string, to: string) => {
    InventoryConfirmationService.getConfirmations(from, to)
      .then(response => {
        confirmations.value = PageUtils.extractCollection(response.data, "confirmations");
      });
  };

  onMounted(() => {
    const from = moment().startOf("month").format("YYYY-MM-DD");
    const to = moment().endOf("month").format("YYYY-MM-DD");
    fetchConfirmations(from, to);
  });

  return {
    fetchConfirmations,
    confirmations
  };
};
