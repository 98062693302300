
  import {defineComponent, ref, watch} from "vue";
  import {initDialog} from "@/views/Orders/OrdersPlanningSetup";
  import router from "@/router";
  import {InventoryConfirmation} from "@/model/InventoryConfirmation";
  import moment from "moment";
  import {t} from "@/mixin/mixins";
  import eventBus from "@/eventbus";
  import {MessageTypes} from "@/components/message-types";
  import OrdersPlanningInventoryConfirmationDialog from "@/views/Orders/OrdersPlanningInventoryConfirmationDialog.vue";

  export default defineComponent({
    name: "OrdersPlanningTable",
    components: {OrdersPlanningInventoryConfirmationDialog},
    props: {
      yearMonth: {
        type: String,
        default: null
      }
    },
    setup(props, context) {
      const footerProps = {
        "items-per-page-options": [-1]
      };

      const headers = [
        {
          text: t("date.text"),
          align: "left",
          value: "date"
        },
        {
          text: t("startTime"),
          align: "left",
          value: "startTime"
        },
        {
          text: t("name"),
          align: "left",
          value: "name"
        },
        {
          text: t("address.address"),
          align: "left",
          value: "address"
        },
        {
          text: t("address.zip"),
          align: "left",
          value: "zip"
        },
        {
          text: t("address.city"),
          align: "left",
          value: "city"
        },
        {
          text: t("order.inventoryConfirmations.confirmed"),
          align: "left",
          value: "confirmationTimestamp"
        },
        {
          text: t("order.inventoryConfirmations.flowers"),
          align: "left",
          value: "flowers"
        },
        {
          text: t("order.inventoryConfirmations.fruitAndVegetables"),
          align: "left",
          value: "fruitAndVegetables"
        },
        {
          text: t("order.inventoryConfirmations.labels"),
          align: "left",
          value: "labels"
        },
        {
          text: t("order.inventoryConfirmations.storage"),
          align: "left",
          value: "storage"
        },
        {
          text: t("order.inventoryConfirmations.targetActualComparison"),
          align: "left",
          value: "targetActualComparison"
        },
        {
          text: t("order.inventoryConfirmations.changedByCustomer"),
          align: "left",
          value: "changedByCustomer"
        },
        {
          text: t("order.inventoryConfirmations.storageRollCageCount"),
          align: "left",
          value: "storageRollCageCount"
        },
        {
          text: t("order.inventoryConfirmations.storageFreezerRollCageCount"),
          align: "left",
          value: "storageFreezerRollCageCount"
        },
        {
          text: t("comment"),
          align: "left",
          value: "comment"
        },
        {
          text: "",
          align: "right",
          value: "orderId",
          sortable: false,
          width: 80
        }
      ];

      const {confirmations, fetchConfirmations} = initDialog(context);

      const ordersPlanningInventoryConfirmationDialog = ref<InstanceType<typeof OrdersPlanningInventoryConfirmationDialog>>();
      const openInventoryConfirmation = (confirmation: InventoryConfirmation) => {
        console.log(confirmation);

        ordersPlanningInventoryConfirmationDialog.value?.open(confirmation)
          .then(result => {
            if (result) {
              let from = moment(props.yearMonth, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
              let to = moment(props.yearMonth, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
              fetchConfirmations(from, to);
            }
          });
      };

      const openOrder = (confirmation: InventoryConfirmation) => {
        const routeData = router.resolve({
          name: "OrderInfo",
          params: {orderId: confirmation.orderId as unknown as string}
        });
        // Open in new tab
        window.open(routeData.href, "_blank");
      };

      const openComment = (confirmation: InventoryConfirmation) => {
        eventBus.$emit(MessageTypes.INFO, confirmation.name, confirmation.comment);
      };

      watch(() => props.yearMonth, (newValue, oldValue) => {
        if (newValue && oldValue && newValue != oldValue) {
          let from = moment(newValue, "YYYY-MM").startOf("month").format("YYYY-MM-DD");
          let to = moment(newValue, "YYYY-MM").endOf("month").format("YYYY-MM-DD");
          fetchConfirmations(from, to);
        }
      });

      return {
        confirmations,
        footerProps,
        headers,
        openComment,
        openInventoryConfirmation,
        openOrder,
        ordersPlanningInventoryConfirmationDialog
      };
    }
  });
