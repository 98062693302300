import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{staticClass:"menu",attrs:{"mini-variant":_vm.isShowMiniVariant,"value":_vm.isShowMenu,"app":"","clipped":"","fixed":""},on:{"input":function($event){return _vm.$emit('input', $event)}},scopedSlots:_vm._u([(_vm.$vuetify.breakpoint.mdAndDown)?{key:"prepend",fn:function(){return [_c(VListItem,{on:{"click":_vm.openUserProfile}},[_c(VListItemAvatar,{staticClass:"elevation-1"},[_c(VAvatar,{attrs:{"color":"white","size":"48"}},[_c('span',[_vm._v(_vm._s(_vm.avatarShortName))])])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"white--text"},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]),_c(VListItemSubtitle)],1)],1)]},proxy:true}:null],null,true)},[_c(VDivider),_c(VList,{staticClass:"pt-0 pl-0",attrs:{"dense":"","nav":"","shaped":""}},[_c(VSubheader,{attrs:{"inset":""}},[_vm._v(" NAVIGATION ")]),_c(VListItemGroup,{attrs:{"color":"primary"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_vm._l((_vm.activeMenuItems),function(item,i){return _c(VListItem,{key:i,staticClass:"menu-item",attrs:{"to":item.to}},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),_c(VListItem,{staticClass:"menu-item"},[_c(VListItemIcon,[_c(VIcon,[_vm._v(" mdi-emoticon-sick-outline ")])],1),_c(VListItemContent,[_c(VListItemTitle,{on:{"click":_vm.openSickLeaveForm}},[_vm._v(" Krankmeldung "),_c(VIcon,[_vm._v(" mdi-open-in-new ")])],1)],1)],1)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }